:root {
  --text-primary: #efefef;
  --container-width: 1440px;
}

/* General */

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;

  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;

  color: var(--text-primary);
}

h1,
h2,
h3 {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
  font-weight: 800;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: var(--text-primary);
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
  }
}

svg {
  fill: var(--text-primary);
}

.container {
  margin-left: auto;
  margin-right: auto;

  width: 100%;
  max-width: var(--container-width);
}

#root {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
}

/* Main visual */

#visual {
  position: fixed;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: radial-gradient(#14213d, #000000);

  overflow: hidden;
}

#visual canvas {
  width: 100%;
  height: 100%;
}

/* Header */

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;

  margin-top: 3rem;
  padding: 0 2rem;
}

header .container {
  display: flex;
}

header .container .titles {
  width: 100%;
}

header .titles * {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  header {
    margin-top: 4rem;
    padding: 0 2rem;
  }
}

/* Nav */

nav {
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  padding: 2rem;

  height: 100%;
}

nav .links {
  display: flex;
  flex-direction: column;

  margin-top: auto;
}

nav .links a {
  display: flex;
  justify-content: center;

  margin: 1rem;

  width: 1.5rem;
  height: 1.5rem;

  transition: color 0.25s ease;
}

nav .links a svg {
  width: 100%;
  height: 100%;
}

nav .links :is(a:hover, a:focus) {
  color: #888888;
}

@media screen and (min-width: 768px) {
  nav .links {
    font-size: 2rem;
  }

  nav .links a {
    width: 2rem;
    height: 2rem;
  }
}

/* Can't use variable in media query, but should match --container-width */
@media screen and (min-width: 1440px) {
  nav {
    right: calc((100% - var(--container-width)) / 2);
  }
}
