:root {
  --text-primary: #efefef;
  --container-width: 1440px;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  color: var(--text-primary);
  margin: 0;
  font-family: Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
}

h1, h2, h3 {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
  font-weight: 800;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
}

a {
  color: var(--text-primary);
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
  }
}

svg {
  fill: var(--text-primary);
}

.container {
  width: 100%;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
}

#root {
  width: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

#visual {
  z-index: -1;
  width: 100%;
  height: 100%;
  background: radial-gradient(#14213d, #000);
  position: fixed;
  overflow: hidden;
}

#visual canvas {
  width: 100%;
  height: 100%;
}

header {
  margin-top: 3rem;
  padding: 0 2rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

header .container {
  display: flex;
}

header .container .titles {
  width: 100%;
}

header .titles * {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  header {
    margin-top: 4rem;
    padding: 0 2rem;
  }
}

nav {
  height: 100%;
  flex-direction: column;
  padding: 2rem;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

nav .links {
  flex-direction: column;
  margin-top: auto;
  display: flex;
}

nav .links a {
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  margin: 1rem;
  transition: color .25s;
  display: flex;
}

nav .links a svg {
  width: 100%;
  height: 100%;
}

nav .links :is(a:hover, a:focus) {
  color: #888;
}

@media screen and (min-width: 768px) {
  nav .links {
    font-size: 2rem;
  }

  nav .links a {
    width: 2rem;
    height: 2rem;
  }
}

@media screen and (min-width: 1440px) {
  nav {
    right: calc((100% - var(--container-width)) / 2);
  }
}

/*# sourceMappingURL=index.98045124.css.map */
